import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import EmailCollector from "../../components/Mailchimp/EmailCollector";

const Desktop = ({ screenType }) => {
  const isLocalhost = window.location.hostname === "localhost";
  return (
    <div className="desktop">
      <div className="overlap-wrapper">
        <div className="promotional-banner"></div>
        <div className="overlap">
          <img
            className="illustration"
            alt="Illustration"
            src="/img/illustrationnew.svg"
          />
          <img
            className="BG-element"
            alt="Bg element"
            src="/img/bg-element.svg"
          />
          <div className="nav">
            <a href="/" className="xvoucher-logo-link">
              <img
                className="xvoucher-logo"
                alt="Xvoucher logo"
                src="/img/xvoucher-logo.svg"
              />
            </a>
            <div className="how-guide">
              <div className="overlap-group">
                <Link to="/how-to-guide" className="text-wrapper">
                  how to guide
                </Link>
                <img className="vector" alt="Vector" src="/img/vector.svg" />
              </div>
            </div>
          </div>
          <img className="header-copy" alt="Header copy" src="/img/v2s.svg" />
          <img
            className="vectorstep1"
            alt="Vectorstep1"
            src="/img/vectorstep1.svg"
          />
          <iframe
            className="step-iframe bg-transparent"
            src={
              isLocalhost
                ? "http://localhost:3000"
                : "https://voucher-poc-dij5-aerynquarmbys-projects.vercel.app/"
            }
            title="Embedded Step"
          />
          <EmailCollector screenType={screenType} />
          <a
            className="element-voucher"
            href="https://shop.1voucher.co.za/?_gl=1*lnti7c*_ga*MTg3NzkxNTAzMS4xNzE4OTY1NjI1*_ga_GWR2JZJFZQ*MTcxOTM0MDc4OC4yLjAuMTcxOTM0MDc4OC42MC4wLjA."
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="overlap-4">
              <div className="rectangle-3" />
              <div className="get-your-here">Buy a</div>
              <img
                className="clip-path-group"
                alt="Clip path group"
                src="/img/clip-path-group.png"
              />
            </div>
          </a>
          <p className="p">
            <img
              src="/img/xionlogo4.svg"
              alt="Xion Global Logo"
              className="xionLogo"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
