import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import EmailCollector from "../../components/Mailchimp/EmailCollector";

const Mobile = ({ screenType }) => {
  const isLocalhost = window.location.hostname === "localhost";
  return (
    <div className="mobile">
      <div className="overlap-wrapper">
        <div className="promotional-banner"></div>
        <div className="overlap">
          <img
            className="BG-element"
            alt="Bg element"
            src="/img/bg-element-m.svg"
          />
          <img
            className="illustration"
            alt="Illustration"
            src="/img/illustration-m.svg"
          />
          <div className="nav">
            <a href="/" className="xvoucher-logo-link">
              <img
                className="xvoucher-logo"
                alt="Xvoucher logo"
                src="/img/xvoucher-logo-m.svg"
              />
            </a>
            <div className="how-guide">
              <div className="overlap-group">
                <Link to="/how-to-guide" className="text-wrapper">
                  how to guide
                </Link>
                <img className="vector" alt="Vector" src="/img/vector-m.svg" />
              </div>
            </div>
          </div>
          <img className="header-copy" alt="Header copy" src="/img/v2s.svg" />
          {/* <div className="back">
            <div className="div">
              <div className="text-wrapper-2">back</div>
              <img className="img" alt="Vector" src="/img/vector-1-m.svg" />
            </div>
          </div> */}
          {/* <div className="step">
            <div className="element-darl-wrapper">
              <img
                className="element-darl"
                alt="Element darl"
                src="/img/1-darl-m.png"
              />
            </div>
            <img
              className="line-arrow"
              alt="Line arrow"
              src="/img/line-arrow-m.png"
            />
            <div className="element-dark-wrapper">
              <img
                className="element-dark"
                alt="Element dark"
                src="/img/2-dark-m.png"
              />
            </div>
            <img
              className="line-arrow-2"
              alt="Line arrow"
              src="/img/line-arrow-1-m.png"
            />
            <div className="img-wrapper">
              <img
                className="element-dark"
                alt="Element dark"
                src="/img/3-dark-m.png"
              />
            </div>
          </div> */}

          <iframe
            className="step-iframe bg-transparent"
            src={
              isLocalhost
                ? "http://localhost:3000"
                : "https://voucher-poc-dij5-aerynquarmbys-projects.vercel.app/"
            }
            title="Embedded Step"
          />
          {/* <p className="select-enter-your">
            select wallet; choose voucher provider; enter voucher pin; redeem
            crypto to shop nation wide
          </p> */}
          {/* <div className="buttons">
            <div className="enter-field">
              <div className="div-wrapper">
                <div className="text-wrapper-3">00000</div>
              </div>
            </div>
          </div>
          <div className="button-field">
            <div className="overlap-2">
              <div className="text-wrapper-4">login/create Web3 wallet</div>
            </div>
          </div>
          <div className="t-cs">
            <div className="overlap-3">
              <div className="rectangle" />
              <p className="i-acknowledge-that-i">
                <span className="span">
                  I acknowledge that I have read &amp; fully agree to Voucher
                  Redemption&#39;s{" "}
                </span>
                <a
                  href="https://voucher-poc.vercel.app/your-terms-link-here"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="text-wrapper-5">Terms &amp; Conditions</span>
                </a>
              </p>
            </div>
          </div> */}
          <EmailCollector screenType={screenType} />
          <a
            className="element-voucher"
            href="https://shop.1voucher.co.za/?_gl=1*lnti7c*_ga*MTg3NzkxNTAzMS4xNzE4OTY1NjI1*_ga_GWR2JZJFZQ*MTcxOTM0MDc4OC4yLjAuMTcxOTM0MDc4OC42MC4wLjA."
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="overlap-4">
              <div className="rectangle-3" />
              <div className="get-your-here">Buy a</div>
              <img
                className="clip-path-group"
                alt="Clip path group"
                src="/img/clip-path-group.png"
              />
            </div>
          </a>
          <p className="p">
            <img
              src="/img/xionlogo4.svg"
              alt="Xion Global Logo"
              className="xionLogo"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
