import React, { useState, useEffect } from "react";
import axios from "axios";

const EmailCollector = ({ screenType }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isPeeled, setIsPeeled] = useState(false);

  useEffect(() => {
    if (screenType === "desktop") {
      import("../../screens/desktop/styles-desktop.css");
    } else if (screenType === "ipad") {
      import("../../screens/ipad/styles-ipad.css");
    } else if (screenType === "mobile") {
      import("../../screens/mobile/styles-mobile.css");
    }
  }, [screenType]);

  const addEmailToAudience = async (email) => {
    try {
      const response = await axios.post('/api/subscribe', { email });
      if (response.status === 200) {
        setMessage("Thanks for subscribing!");
      } else {
        setMessage("Failed to subscribe. Please try again.");
      }
    } catch (error) {
      console.error("Error adding email to audience", error);
      setMessage("Failed to subscribe. Please try again.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (email) {
      addEmailToAudience(email);
    }
  };

  const handlePeelOff = () => {
    setIsPeeled(true);
  };

  return (
    <div className="email-collector">
      {!isPeeled ? (
        <div className="peel-layer" onMouseDown={handlePeelOff}>
          Subscribe To WIN $USDT
        </div>
      ) : (
        <div className="email-input-container">
          <input
            className="enter-your-email-to"
            placeholder="Enter Your Best Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default EmailCollector;
