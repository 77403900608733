import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import EmailCollector from "../../components/Mailchimp/EmailCollector";

const Ipad = ({ screenType }) => {
  const isLocalhost = window.location.hostname === "localhost";
  return (
    <div className="tablet">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <img
              className="BG-element"
              alt="Bg element"
              src="/img/bg-element_ipad.svg"
            />
            <img
              className="illustration"
              alt="Illustration"
              src="/img/illustration.svg"
            />
            <div className="nav">
              <img
                className="xvoucher-logo"
                alt="Xvoucher logo"
                src="/img/xvoucher-logo.svg"
              />
              <div className="how-guide">
                <div className="div">
                  <Link to="/how-to-guide" className="text-wrapper">
                    how to guide
                  </Link>
                  <img className="vector" alt="Vector" src="/img/vector.svg" />
                </div>
              </div>
            </div>
            <img className="header-copy" alt="Header copy" src="/img/v2s.svg" />
            {/* <div className="step">
              <div className="element-dark-wrapper">
                <img
                  className="element-dark"
                  alt="Element dark"
                  src="/img/1-dark.png"
                />
              </div>
              <img
                className="line-arrow"
                alt="Line arrow"
                src="/img/line-arrow.png"
              />
              <div className="img-wrapper">
                <img className="img" alt="Element dark" src="/img/2-dark.png" />
              </div>
              <img
                className="line-arrow-2"
                alt="Line arrow"
                src="/img/line-arrow-1.png"
              />
              <div className="overlap-2">
                <img className="img" alt="Element dark" src="/img/3-dark.png" />
              </div>
            </div> */}

            <iframe
              className="step-iframe bg-transparent"
              src={
                isLocalhost
                  ? "http://localhost:3000"
                  : "https://voucher-poc-dij5-aerynquarmbys-projects.vercel.app/"
              }
              title="Embedded Step"
            />
            {/* <p className="select-enter-your">
              select wallet; choose voucher provider; enter voucher pin; redeem
              crypto to shop nation wide
            </p> */}
            {/* <div className="buttons">
              <div className="button-field">
                <div className="div-wrapper">
                  <div className="text-wrapper-2">login/create Web3 wallet</div>
                </div>
              </div>
              <div className="enter-field">
                <div className="overlap-3">
                  <div className="text-wrapper-3">00000</div>
                </div>
              </div>
            </div> */}
            {/* <div className="t-cs">
              <div className="overlap-4">
                <div className="rectangle" />
                <p className="i-acknowledge-that-i">
                  <span className="span">
                    I acknowledge that I have read &amp; fully agree to Voucher
                    Redemption&#39;s{" "}
                  </span>
                  <a
                    href="https://voucher-poc.vercel.app/your-terms-link-here"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="text-wrapper-4">
                      Terms &amp; Conditions
                    </span>
                  </a>
                </p>
              </div>
            </div> */}
            <EmailCollector screenType={screenType} />
            <div className="element-voucher">
              <div className="overlap-5">
                <div className="rectangle-3" />
                <div className="get-your-here">Buy a</div>
                <img
                  className="clip-path-group"
                  alt="Clip path group"
                  src="/img/clip-path-group.png"
                />
              </div>
            </div>
            <p className="p">
              <img
                src="/img/xionlogo4.svg"
                alt="Xion Global Logo"
                className="xionLogo"
              />
            </p>
          </div>
          {/* <div className="back">
            <div className="overlap-6">
              <div className="text-wrapper-5">back</div>
              <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Ipad;
